import React from 'react';
import styled from 'styled-components';

import { media } from '../../../styles/media';

interface LargeButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'as'> {
  as?: React.ElementType;
  children?: React.ReactNode;
}

export const LargeButton = styled.button<LargeButtonProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  text-align: center;
  letter-spacing: 0.583333px;
  text-transform: uppercase;
  width: 304px;
  height: 68px;
  background: var(--primary-1);
  color: var(--white-2);

  &:hover {
    background: var(--primary-1-hover);
  }

  &:active {
    background: var(--primary-1-active);
  }

  @media ${media.mdUp} {
    width: 400px;
    height: 80px;
  }
`;

export default LargeButton;
