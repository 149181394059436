import styled from 'styled-components';

import { media } from '../../styles/media';

export const MaxWidthContainer = styled.div`
  box-sizing: border-box;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  @media ${media.smUp} {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  @media ${media.lgUp} {
    max-width: 1150px;
    padding-left: 0;
    padding-right: 0;
  }
`;
