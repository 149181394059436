import { LightTheme } from '@csscompany/csds-themes';
import {
  useEscapableToggle,
  useTransitionLinks,
  PageContextProvider,
} from '@publicss/components';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import translations from '../constants/translations.json';
import { useLogin } from '../hooks';
import Form from '../styles/Form';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import { theme } from '../styles/media';
import Footer from './Footer';
import GlobalMeta from './GlobalMeta';
import Nav from './Nav';
import NavMenu from './NavMenu';
import NavMobile from './NavMobile';

export function Layout({ children, pageContext, location }) {
  const loginProps = useLogin();
  const { global } = pageContext;
  const hasCareerForm = location.pathname.includes('careers');
  const [open, setOpen] = useEscapableToggle(false);
  const navClassName = loginProps.isLoggedIn ? 'bg-white-1' : 'bg-black-2';

  useTransitionLinks();
  return (
    <PageContextProvider
      {...pageContext}
      {...loginProps}
      translations={translations}
      csdsTheme={LightTheme}
      defaultLang="en">
      <div id="layout" className="relative">
        <GlobalStyles global={global} />
        <GlobalMeta />
        <Typography />
        <Form />
        <ThemeProvider theme={theme}>
          <Nav global={global} open={open} setOpen={setOpen} />
          <NavMobile
            global={global}
            open={open}
            setOpen={setOpen}
            className={navClassName}
          />
          <NavMenu global={global} open={open} setOpen={setOpen} />
          {children}
          <Footer
            id="footer"
            hasAdditionalLinks={!hasCareerForm}
            hasCareerForm={hasCareerForm}
            location={location}
          />
        </ThemeProvider>
      </div>
    </PageContextProvider>
  );
}

export default Layout;
