import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useRef, useLayoutEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

export const getElement = (selector) => {
  try {
    return document.querySelector(selector);
  } catch (_) {
    return undefined;
  }
};

export const Sticky = ({
  topBoundary,
  bottomBoundary,
  pinSpacing = false,
  scrollTrigger = {},
  ...props
}) => {
  const ref = useRef(null);

  const topOffset = () => {
    if (!topBoundary) return 'top';

    const el = getElement(topBoundary);
    if (!el) return topBoundary;

    const offset = (el.offsetTop || 0) + (el.offsetHeight || 0);
    return `top ${offset}`;
  };

  const bottomOffset = () => {
    if (!bottomBoundary) return undefined;

    const el = getElement(bottomBoundary);
    if (!el) return bottomBoundary;

    const offsetH = el.offsetHeight || 0;
    return `+=${offsetH}`;
  };

  useLayoutEffect(() => {
    gsap.to(ref.current, {
      scrollTrigger: {
        trigger: ref.current,
        start: topOffset,
        end: bottomOffset,
        pin: true,
        pinSpacing,
        ...scrollTrigger,
      },
    });
  }, []);

  return <div {...props} ref={ref} />;
};
