import {
  getUrlDomain,
  isBrowser,
  getUrlParams,
  getReferrer,
  getFullUrl,
} from '@publicss/utils';
import { useEffect, useState } from 'react';

type Params = { [key: string]: string };

class Cookie {
  params: Params = {};

  setParamsCookies(cookieKey: string): void {
    Object.keys(this.params).forEach((key) => {
      if (typeof key !== 'undefined') {
        this.setCookie(`${cookieKey}${key}`, this.params[key], 30);
      }
    });
  }

  setCookie(name: string, value: string, days: number): void {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  getCookies(cookieKey: string): Params {
    const cookies = document.cookie.split(';') || [];

    cookies.forEach((cookie) => {
      const indexEq = cookie.indexOf('=');
      const key = cookie.slice(0, indexEq);
      const value = cookie.slice(indexEq + 1);
      if (key.indexOf(cookieKey) !== -1) {
        const index = key.replace(cookieKey, '').replace(' ', '');
        this.params[index] = value;
      }
    });

    return this.params;
  }

  eraseCookie(name: string): void {
    document.cookie =
      name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}

class UTM {
  customUTM: string[] = ['fbclid', 'gclid'];
  cookies = new Cookie();
  cookieKey = '_cookieUTM';
  previousUtms: Params;
  step = '';
  hasUtms = false;
  knownThirdPartyReferrerUrl: boolean;
  utms: Params = this.cookies.params;
  urlParameters: Params;

  utmKeys = [
    ['ft_utm_source__c', 'utm_source', 'lt_utm_source__c'],
    ['ft_utm_medium__c', 'utm_medium', 'lt_utm_medium__c'],
    ['ft_utm_campaign__c', 'utm_campaign', 'lt_utm_campaign__c'],
    ['ft_utm_term__c', 'utm_term', 'lt_utm_term__c'],
    ['ft_utm_content__c', 'utm_content', 'lt_utm_content__c'],
    ['ft_utm_adgroup__c', 'utm_adgroup', 'lt_utm_adgroup__c'],
    ['ft_fbclid__c', 'fbclid', 'lt_fbclid__c'],
    ['ft_gclid__c', 'gclid', 'lt_gclid__c'],
  ];

  public static getUtm = (urlParams: { [k: string]: string }) => {
    const utm = new UTM(urlParams);
    utm.setUtms();
    return utm.utms;
  };

  constructor(urlParameters: Params) {
    this.knownThirdPartyReferrerUrl = !getReferrer()?.includes(getUrlDomain());
    this.urlParameters = urlParameters;
    this.previousUtms = this.getUTMCookies();
  }

  getUTMCookies() {
    return this.cookies.getCookies(this.cookieKey);
  }

  setUTMCookies() {
    return this.cookies.setParamsCookies(this.cookieKey);
  }

  setUtms() {
    if (getReferrer()?.includes(getUrlDomain())) return;

    this.isolateUtms();
    this.processUtms();
    this.setUTMCookies();
  }

  processUtms() {
    if (this.hasUtms) {
      // IF  Has utms values
      this.step = 'A';
    } else if (!this.hasUtms && this.getOrganicReferrerSourcePage()) {
      //IF NO UTMS BUT KNOWN THIRD PARTY REFERRAL
      this.step = 'B';
    } else {
      // IF NOT UTMS AND THRID PARTY REFERRAL
      this.step = 'C';
    }

    switch (this.step) {
      case 'A':
        this.stepA();
        break;
      case 'B':
        this.stepB();
        break;
      case 'C':
        this.stepC();
        break;
      default:
        console.error('NO STEP UTMS');
        break;
    }

    Object.assign(this.utms, {
      lt_fbclid__c: undefined,
      ft_fbclid__c: undefined,
      lt_gclid__c: undefined,
      ft_gclid__c: undefined,
    });
    this.utms['lt_fbclid__c'];

    this.cookies.params = this.utms;
  }

  stepA() {
    for (let i = 0; i < this.utmKeys.length; i++) {
      const [firstTouchKey, key, lastTouchKey] = this.utmKeys[i];

      if (this.utms[key] && this.utms[key].length > 0) {
        //IF A VALUE EXISTS ON THE URL
        if (
          firstTouchKey in this.previousUtms &&
          this.previousUtms[firstTouchKey].length > 0
        ) {
          //IF FIRST TOUCH ALREADY EXISTS
          Object.assign(this.utms, {
            [lastTouchKey]: this.utms[key],
          });
        } else {
          //IF IS FIRST TIME LEAD
          Object.assign(this.utms, {
            [firstTouchKey]: this.utms[key],
          });
        }
      }

      // DELETE THE PROPERTY
      // delete this.utms[key];
    }
  }

  stepB() {
    // ASSIGN organic_referrer_source_page__c TO THE KNOWN REFERRER
    Object.assign(this.utms, {
      // organic_referrer_source_page__c: document.referrer,
      organic_referrer_source_page__c: this.getOrganicReferrerSourcePage(),
    });

    // ASSIGN PROPERTY FOR UTMS
    for (let i = 0; i < this.utmKeys.length; i++) {
      const [firstTouchKey, key, lastTouchKey] = this.utmKeys[i];
      if (key.includes('fbclid') || key.includes('gclid')) return;
      if (
        firstTouchKey in this.previousUtms &&
        this.previousUtms[firstTouchKey].length > 0
      ) {
        //IF FIRST TOUCH ALREADY EXISTS
        if (key.includes('source')) {
          Object.assign(this.utms, {
            [lastTouchKey]: this.getOrganicReferrerSourcePage(),
          });
        } else if (key.includes('campaign')) {
          Object.assign(this.utms, {
            [lastTouchKey]: this.utms.organic_referrer_source_page__c,
          });
        } else if (key.includes('medium')) {
          Object.assign(this.utms, {
            [lastTouchKey]: 'organic',
          });
        }
      } else {
        //IF IS FIRST TIME LEAD
        if (key.includes('source')) {
          Object.assign(this.utms, {
            [firstTouchKey]: this.getOrganicReferrerSourcePage(),
          });
        } else if (key.includes('campaign')) {
          Object.assign(this.utms, {
            [firstTouchKey]: this.utms.organic_referrer_source_page__c,
          });
        } else if (key.includes('medium')) {
          Object.assign(this.utms, {
            [firstTouchKey]: 'organic',
          });
        }
      }

      // DELETE THE PROPERTY
      // delete this.utms[key];
    }
  }

  stepC() {
    for (let i = 0; i < this.utmKeys.length; i++) {
      const [firstTouchKey, key, lastTouchKey] = this.utmKeys[i];

      if (key.includes('fbclid') || key.includes('gclid')) return;
      if (
        firstTouchKey in this.previousUtms &&
        this.previousUtms[firstTouchKey].length > 0
      ) {
        if (key.includes('medium')) {
          //IF FIRST TOUCH ALREADY EXISTS
          Object.assign(this.utms, {
            [lastTouchKey]: 'direct',
            [key]: 'direct',
          });
        } else {
          Object.assign(this.utms, {
            [lastTouchKey]: undefined,
            [key]: undefined,
          });
        }
      } else {
        if (key.includes('medium')) {
          //IF IS FIRST TIME LEAD
          Object.assign(this.utms, {
            [firstTouchKey]: 'direct',
            [key]: 'direct',
          });
        } else {
          Object.assign(this.utms, {
            [firstTouchKey]: undefined,
            [key]: undefined,
          });
        }
      }

      // DELETE THE PROPERTY
      // delete this.utms[key];
    }
  }

  getOrganicReferrerSourcePage(
    referrerUrl: string = document.referrer.toLowerCase()
  ): string {
    if (referrerUrl.includes('google')) {
      return 'google';
    } else if (referrerUrl.includes('bing')) {
      return 'bing';
    } else if (referrerUrl.includes('yahoo')) {
      return 'yahoo';
    } else if (referrerUrl.includes('facebook')) {
      return 'facebook';
    } else if (referrerUrl.includes('linkedin')) {
      return 'linkedin';
    } else if (referrerUrl.includes('instagram')) {
      return 'instagram';
    } else if (referrerUrl.includes('youtube')) {
      return 'youtube';
    } else if (referrerUrl.includes('tiktok')) {
      return 'tiktok';
    } else if (referrerUrl.includes('whatsapp')) {
      return 'whatsapp';
    } else if (referrerUrl.includes('twitter')) {
      return 'twitter';
    } else if (document.referrer) {
      const domain = new URL(document.referrer).hostname
        .replace('www.', '')
        .split('.');
      return domain[0];
    }

    return '';
  }

  appendFirstTouch(key: keyof typeof this.utms) {
    const map = {
      utm_source: 'ft_utm_source__c',
      utm_medium: 'ft_utm_medium__c',
      utm_campaign: 'ft_utm_campaign__c',
      utm_term: 'ft_utm_term__c',
      utm_content: 'ft_utm_content__c',
      utm_adgroup: 'ft_utm_adgroup__c',
      ft_utm_campaign__c: 'ft_utm_campaign__c',
      ft_utm_content__c: 'ft_utm_content__c',
      ft_utm_medium__c: 'ft_utm_medium__c',
      ft_utm_source__c: 'ft_utm_source__c',
      ft_utm_term__c: 'ft_utm_term__c',
      ft_utm_adgroup__c: 'ft_utm_adgroup__c',
      fbclid: 'ft_fbclid__c',
      ft_fbclid__c: 'ft_fbclid__c',
      gclid: 'ft_gclid__c',
      ft_gclid__c: 'ft_gclid__c',
    };

    // duplicate value with first touch key
    if (map[key as keyof typeof map]) {
      this.utms[map[key as keyof typeof map] as keyof typeof this.utms] =
        this.utms[key];
    }

    // DELETE THE PROPERTY
    delete this.utms[key];
  }

  appendLastTouch(key: keyof typeof this.utms) {
    const map = {
      utm_source: 'lt_utm_source__c',
      utm_medium: 'lt_utm_medium__c',
      utm_campaign: 'lt_utm_campaign__c',
      utm_term: 'lt_utm_term__c',
      utm_content: 'lt_utm_content__c',
      utm_adgroup: 'lt_utm_adgroup__c',
      fbclid: 'lt_fbclid__c',
      gclid: 'lt_gclid__c',
    };

    // duplicate value with last touch key
    if (map[key as keyof typeof map]) {
      this.utms[map[key as keyof typeof map] as keyof typeof this.utms] =
        this.utms[key];
    }

    // DELETE THE PROPERTY
    delete this.utms[key];
  }

  isolateUtms(): void {
    for (const [key, value] of Object.entries(this.urlParameters)) {
      if (key.includes('utm') || this.customUTM.includes(key)) {
        this.hasUtms = true;
        this.utms[key] = value;
      }
    }
  }
}

// Whitelisted query params
// const UTM_QUERY_PARAMS = ['Upload_Source__c'];

// UTM Hook
export function useUTMCookies() {
  // Server side early return
  if (!isBrowser()) return {};
  const [urlParams] = useState(getUrlParams());
  const [utms, setUtms] = useState<any>({
    Previous_Page_URL__c: getReferrer(),
    Upload_Source__c: getFullUrl(),
  });

  useEffect(() => {
    setUtms((prevUtms) => {
      return {
        ...prevUtms,
        Previous_Page_URL__c: getReferrer(),
        Upload_Source__c: getFullUrl(),
      };
    });
  }, [getFullUrl()]);

  useEffect(() => {
    const utm = UTM.getUtm(urlParams as Params);
    setUtms({
      ...utm,
      Previous_Page_URL__c: getReferrer(),
      Upload_Source__c: getFullUrl(),
    });
  }, []);

  // console.log('REFERRER: ', prevPage);
  // console.log('DOMAIN: ', curPage);
  // console.log('PARAMS: ', JSON.stringify(urlParams, undefined, 2));
  // console.log('UTMS: ', JSON.stringify(utms, undefined, 2));

  //SET UTMS TO COOKIES

  //GET COOKIES
  // return merge(utm.getUTMCookies(), getUrlParams(UTM_QUERY_PARAMS));

  // return merge(utms, getUrlParams(UTM_QUERY_PARAMS));
  return utms;
}
