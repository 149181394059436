import * as React from 'react';
import { forwardRef } from 'react';

const SvgClose = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.95 15.364 9 20.314l1.414 1.414 4.95-4.95 4.95 4.95 1.414-1.414-4.95-4.95 4.95-4.95L20.314 9l-4.95 4.95L10.414 9 9 10.414l4.95 4.95Z"
      fill="#FCF5ED"
    />
    <mask
      id="Close_svg__a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={9}
      y={9}
      width={13}
      height={13}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.95 15.364 9 20.314l1.414 1.414 4.95-4.95 4.95 4.95 1.414-1.414-4.95-4.95 4.95-4.95L20.314 9l-4.95 4.95L10.414 9 9 10.414l4.95 4.95Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#Close_svg__a)">
      <path fill="#E09164" d="M0 0h30v30H0z" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgClose);
export default ForwardRef;
