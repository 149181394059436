import styled, { css } from 'styled-components';

import { RoundButton, RoundButtonProps } from './RoundButton';

function getPathFill({ variant }) {
  switch (variant) {
    case 'dark':
      return css`
        fill: var(--white-3);
      `;
    case 'light':
      return css`
        fill: var(--black-1);
      `;
    case 'primary':
      return css`
        fill: var(--primary-1);
      `;
    default:
      return css`
        fill: var(--primary-1);
      `;
  }
}

interface MoreButtonProps extends Omit<RoundButtonProps, 'variant'> {
  variant?: 'light' | 'dark' | 'primary';
  bg?: string;
  bgHover?: string;
  bgActive?: string;
}

export const MoreButton = styled(RoundButton).attrs({
  onClick: (e) => {
    e.preventDefault();
    document
      .querySelector(e.currentTarget.getAttribute('href'))
      .scrollIntoView({
        behavior: 'smooth',
      });
  },
})<MoreButtonProps>`
  width: 80px;
  height: 80px;
  border: 2px solid var(--primary-1);
  border-radius: 40px;
  path {
    ${getPathFill}
  }
  /**
      There is no hover state or active state for these buttons
   */
  ${(props) => css`
    background: var(--${props.bg});
  `};

  &:hover {
    ${(props) => css`
      background: var(--${props.bgHover});
    `};
  }
  &:active {
    ${(props) => css`
      background: var(--${props.bgActive});
    `};
  }
`;

export default MoreButton;
