import styled, { css } from 'styled-components';

import { media } from '../../../styles/media';

export interface RoundButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'variant'> {
  variant: 'dark' | 'light' | 'play-light' | 'play-dark';
  size?: 'small' | 'medium' | 'large' | 'play-button';
}

function getVariantStyles({ variant }: RoundButtonProps) {
  switch (variant) {
    case 'light':
      return css`
        background: var(--white-1);
        &:hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.06),
              rgba(0, 0, 0, 0.06)
            ),
            var(--white-1);
        }
        &:active {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0.12)
            ),
            var(--white-1);
        }
      `;

    case 'dark':
      return css`
        &:hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.06),
              rgba(0, 0, 0, 0.06)
            ),
            var(--white-2);
        }
        &:active {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0.12)
            ),
            var(--white-2);
        }
      `;
    case 'play-light':
      return css`
        background: var(--white-1);
        &:hover {
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.5),
              rgba(255, 255, 255, 0.5)
            ),
            var(--white-1);
        }
        &:active {
          background: var(--white-1);
        }
      `;
    case 'play-dark':
      return css`
        background: var(--white-2);
        &:hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.06),
              rgba(0, 0, 0, 0.06)
            ),
            var(--white-2);
        }
        &:active {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0.12)
            ),
            var(--white-2);
        }
      `;
  }
}

function getSizeStyles({ size }: RoundButtonProps) {
  switch (size) {
    case 'large':
      return css`
        width: 104px;
        height: 104px;
      `;

    case 'medium':
      return css`
        width: 50px;
        height: 50px;
      `;
    case 'play-button':
      return css`
        width: 80px;
        height: 80px;
        @media ${media.mdUp} {
          width: 104px;
          height: 104px;
        }
      `;
    default:
      return css`
        width: 50px;
        height: 50px;
      `;
  }
}

/**
 * Used for play and pause button
 * @returns
 */
export const RoundButton = styled.button<RoundButtonProps>`
  background: var(--white-2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  ${getVariantStyles}
  ${getSizeStyles}
`;

export default RoundButton;
