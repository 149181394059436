import { useEffect } from 'react';

export function useContactLinksClick() {
  useEffect(() => {
    const contactLinks = document.querySelectorAll("a[href='#contact']");
    const contact = document.querySelector('#contact');

    contactLinks.forEach((link) =>
      link.addEventListener('click', (e) => {
        e.preventDefault();
        contact.scrollIntoView({ behavior: 'smooth' });
      })
    );
  }, []);
}

export default useContactLinksClick;
