import { Close } from '@publicss/icons';
import React from 'react';
import styled from 'styled-components';

import { media } from '../../../styles/media';

export const PillButton = styled.button`
  display: grid;
  place-items: center;
  width: 50px;
  height: 50px;
  background: var(--white-2);
  border-radius: 100px;
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
      var(--white-2);
  }
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
      var(--white-2);
  }
  @media ${media.mdUp} {
    width: 70px;
  }
`;

export const CloseButton = (props) => (
  <PillButton {...props}>
    <Close />
  </PillButton>
);
export default PillButton;
