import { isBrowser } from '@publicss/utils';
import { useEffect, useState } from 'react';

interface Redirect {
  to: string;
  from: string;
}

export function getCurrentPath() {
  return isBrowser() ? location?.pathname || '/' : '';
}

export function getNavLang(langs?: string[]) {
  return isBrowser() && langs
    ? langs.find((lang) => location.href.includes(lang)) || langs[0]
    : '';
}

export function getRedirect(
  redirects: Redirect[],
  path: string = getCurrentPath()
) {
  if (!path) return;

  const findRedirect = ({ from }) => {
    if (from) return from.toLowerCase() === path.toLowerCase();
    return;
  };
  const toPath = redirects?.find(findRedirect)?.to;
  return toPath;
}

export function getHomeRedirect(redirects: Redirect[], langs: string[]) {
  const lang = getNavLang(langs);
  const toPath = getRedirect(redirects, '/');
  if (!toPath) return '/';
  if (lang) return `/${lang}/`;
  return toPath;
}

export function useClientRedirects(redirects: Redirect[], langs: string[]) {
  const path = getCurrentPath();

  useEffect(() => {
    const toPath = ['/', '/:lang/'].includes(path)
      ? getHomeRedirect(redirects, langs)
      : getRedirect(redirects, path);

    if (toPath) location.replace(toPath);
  }, [redirects, path]);
}

export function useSecondsRedirect(timer: number, toPath = '/') {
  const [seconds, setSeconds] = useState(timer);

  useEffect(() => {
    const timerId = setTimeout(() => setSeconds(seconds - 1), 1000);

    return () => clearTimeout(timerId);
  }, [seconds]);

  // Redirect
  if (seconds < 1) document.location.assign(toPath);
  return seconds;
}

export default useClientRedirects;
