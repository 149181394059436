import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config.js';

export const fullTailwindConfig = resolveConfig(tailwindConfig);
export const theme = fullTailwindConfig.theme;
export const screens = theme.screens;
export const colors = theme.colors;
export const spacing = theme.spacing;

export const media = {
  smUp: `(min-width: ${screens.sm})`,
  mdUp: `(min-width: ${screens.md})`,
  lgUp: `(min-width: ${screens.lg})`,
  xlUp: `(min-width: ${screens.xl})`,
  smDown: `(max-width: ${screens.sm})`,
  mdDown: `(max-width: ${screens.md})`,
};
