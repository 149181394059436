import { Link, GatsbyLinkProps } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';

interface TextLinkProps extends GatsbyLinkProps<unknown> {
  as?: React.ElementType;
  children?: React.ReactNode;
  color?: 'primary-1' | 'black-2' | 'brown-1';
}

export const TextLink = styled(Link)<TextLinkProps>`
  font-size: 16px;
  line-height: 30px;

  ${({ color }) => {
    if (color) {
      return css`
        color: var(--${color});
      `;
    }
  }}

  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;

export default TextLink;
