import * as React from 'react';
import { forwardRef } from 'react';

const SvgPlus = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 .5H4v4H0v2h4v4h2v-4h4v-2H6v-4Z"
      fill="#967F78"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPlus);
export default ForwardRef;
