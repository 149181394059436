import { useSessionStorage } from 'react-use';

interface User extends Record<string, any> {
  loggedIn: boolean;
  email?: string;
  message?: string;
}

export type LoginProps = {
  username: string;
  password: string;
};

async function fetchUser(data: LoginProps) {
  const body = JSON.stringify(data);
  const options = { method: 'POST', body };

  try {
    const candidate = await fetch('/api/login', options).then((res) =>
      res.json()
    );

    return {
      ...candidate,
      loggedIn: !!candidate?.email,
    };
  } catch (error) {
    return {
      loggedIn: false,
      message: error.message,
    };
  }
}

export function useLogin() {
  const [currentUser, setCurrentUser] = useSessionStorage<User>('current_user');

  return {
    currentUser,
    isLoggedIn: !!currentUser?.email,
    async login(data: LoginProps): Promise<void> {
      return fetchUser(data).then(setCurrentUser);
    },
  };
}
