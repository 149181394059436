import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React from 'react';

import Layout from './src/components/Layout';
import './src/css/index.css';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

// Adds a shared React Context for Prismic preview sessions.
export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
);
