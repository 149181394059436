import * as React from 'react';
import { forwardRef } from 'react';

const SvgPlay = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 15.5 12 22V9l10 6.5Z"
      fill="#E09164"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPlay);
export default ForwardRef;
