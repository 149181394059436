import cx from 'classnames';
import React, { FunctionComponent, useState, useEffect } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

export interface PlayerControllerProps {
  playing?: boolean;
  volume?: number;
  muted?: boolean;
  setVolume: (volume: number) => void;
  togglePlay: () => void;
  toggleMuted: () => void;
}

export interface PlayerProps extends ReactPlayerProps {
  Controller?: FunctionComponent<PlayerControllerProps>;
}

function usePlayerState(props): PlayerControllerProps {
  const [playing, setPlaying] = useState(props.playing);
  const [muted, setMuted] = useState(props.muted);
  const [volume, setVolume] = useState(100);
  const togglePlay = () => setPlaying(!playing);
  const toggleMuted = () => setMuted(!muted);

  useEffect(() => {
    if (playing !== props.playing) togglePlay();
  }, [props.playing]);

  return {
    playing,
    volume,
    muted,
    setVolume,
    togglePlay,
    toggleMuted,
  };
}

export function Player({ url, className, Controller, ...props }: PlayerProps) {
  const hasController = !!Controller;
  const state = hasController ? usePlayerState(props) : undefined;
  const controlProps = hasController
    ? {
        playing: state.playing,
        volume: state.volume / 100,
        muted: state.muted,
      }
    : {};

  return (
    <div className={cx('player relative', className)}>
      <ReactPlayer
        {...props}
        {...controlProps}
        className={'player-video'}
        url={url}
        controls={!hasController}
      />
      {hasController && <Controller {...props} {...state} />}
    </div>
  );
}
