import { createGlobalStyle } from 'styled-components';

import { media } from './media';

// TODO: Create styled components and delete this.
const Form = createGlobalStyle`
.form--element--select {

    border: 0 !important;
    padding: 0 !important;
    appearance: none;
    width: 100%;
    // height: 34px;
    height: 40px;
    line-height: 40px;
    letter-spacing: -0.25px !important;
    color: var(--brown-2) !important;
    font-size: 20px !important;

    @media ${media.mdUp} {
      font-size: 32px !important;
    }
    &.secondary {
      color: var(--white) !important;
    }
    &:focus {
      outline: 0;
    }
    optgroup {
      font-size: 16px;
      color: var(--black);
    }
  }

  .react-select__control {
    border: 0 !important;
    background: none !important;
    &:focus, &:focus-within {
      box-shadow: 0 0 0 !important;
      outline: 0 !important;
    }
  }
  .react-select__value-container {
    padding: 0 !important;
    font-size: 20px !important;

    @media ${media.mdUp} {
      font-size: 32px !important;
    }
  }
  .react-select__indicator {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1.00312L10.9727 -4.49057e-08L6.7541 4.23053L5.98907 5.06661L5.22404 4.23053L1.00546 0.0218074L-4.48008e-08 1.02492L5.98907 7L12 1.00312Z' fill='%23B5B3AB'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    svg {
      display: none;
    }
  }
  .react-select__control--menu-is-open {
    .react-select__indicators {
        transition: .5s;
        transform: rotate(180deg);
    }
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__placeholder {
    font-size: 20px !important;
    @media ${media.mdUp} {
      font-size: 32px !important;
    }
    color: var(--brown-2) !important;
  }
  .react-select__single-value {
    color: var(--brown-2) !important;
    font-size: 20px !important;
    @media ${media.mdUp} {
      font-size:32px !important;
    }
  }
  .react-select__dropdown-indicator:hover {
    color: var(--grey-4) !important;
  }
  .react-select__dropdown-indicator {

    svg {
      color: var(--grey-4) !important;
    }
  }
  .react-select__control--menu-is-open {
    .react-select__dropdown-indicator {
      color: var(--grey-4) !important;
    }
  }
  .react-select__option {
    opacity: 0;
  }
  .react-select__menu {
    margin-top: 20px !important;
    z-index: 100 !important;
    max-height: 270px !important;
    border-radius: 0 !important;
    border-color: var(--grey-3) !important;
    background: var(--black-2) !important;
  }
  .react-select__menu-list {
    padding: 0 10px !important;
    max-height: 270px !important;
  }
  .react-select__option {
    font-size: 20px !important;
    letter-spacing: -.25px !important;
    font-weight: 500 !important;
    padding: 10px 0 !important;
    border-bottom: 1px solid var(--grey-3) !important;
    color: var(--grey-4) !important;
    @media ${media.mdUp} {
      font-size: 32px !important;
    }
    &:last-of-type {
      border: 0 !important;
    }
    &:hover {
      color: var(--grey-5) !important;
      background: none !important;
    }
    &.react-select__option--is-selected {
      color: var(--grey-6) !important;
      background: none !important;
    }
    &.react-select__option--is-focused {
      background: none !important;
    }
  }
`;

export default Form;
