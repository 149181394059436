import React from 'react';
import styled from 'styled-components';

import { useBodyClassName, useSecondsRedirect } from '../hooks';

const Section = styled.section`
  .container {
    height: 100vh;
    align-items: center;
    & > div {
      width: 100%;
    }
  }
  h1 {
    font-size: 158px;
  }
  .countdown {
    a {
      text-decoration: underline;
    }
  }
`;

export interface Block404Props {
  homePath?: string;
}

// TODO: Use Translations
export function Block404({ homePath }: Block404Props) {
  useBodyClassName('fourohfour');
  const seconds = homePath ? useSecondsRedirect(5, homePath) : undefined;

  return (
    <Section id="block-404">
      <div className="container flex text-center">
        <div>
          <h1 className="color--grey-5">404</h1>
          <p>Page not found!</p>
          {homePath && (
            <p className="countdown">
              You'll go back to the homepage in {seconds} seconds (or{' '}
              <a href={homePath}>click here</a>).
            </p>
          )}
        </div>
      </div>
    </Section>
  );
}

export default Block404;
