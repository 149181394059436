import { useEffect } from 'react';

export function useBodyClassName(className) {
  useEffect(() => {
    document.body.classList.add(className);

    // unmount
    return () => document.body.classList.remove(className);
  }, [className]);
}

export default useBodyClassName;
