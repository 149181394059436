import classNames from 'classnames';
import styled from 'styled-components';

interface BgOverlayProps {
  isOpen: boolean;
  bgColor?: string;
}

export const getBgColor = ({ bgColor }: Pick<BgOverlayProps, 'bgColor'>) =>
  `background: ${bgColor || 'rgba(0, 0, 0, 0.8)'};`;

export const BgOverlay = styled.div.attrs<BgOverlayProps>((props) => ({
  ...props,
  className: classNames(props.className, { '--open': props.isOpen }),
}))<BgOverlayProps>`
  display: flex;

  //align-items: center;
  //justify-content: center;
  background: transparent;
  @keyframes on-open {
    from {
      background: rgba(0, 0, 0, 0.1);
    }
    to {
      ${getBgColor};
    }
  }
  transition: all;
  &.--open {
    position: fixed;
    ${getBgColor};
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: on-open 0.3s linear;
    align-items: center;
    justify-content: center;
  }
`;
