import { createGlobalStyle } from 'styled-components';

import SuisseIntBoldWoff from '../assets/fonts/SuisseIntl-Bold.woff';
import SuisseIntBoldWoff2 from '../assets/fonts/SuisseIntl-Bold.woff2';
import SuisseIntLightWoff from '../assets/fonts/SuisseIntl-Light.woff';
import SuisseIntLightWoff2 from '../assets/fonts/SuisseIntl-Light.woff2';
import SuisseIntMediumWoff from '../assets/fonts/SuisseIntl-Medium.woff';
import SuisseIntMediumWoff2 from '../assets/fonts/SuisseIntl-Medium.woff2';
import SuisseIntRegularWoff from '../assets/fonts/SuisseIntl-Regular.woff';
import SuisseIntRegularWoff2 from '../assets/fonts/SuisseIntl-Regular.woff2';
import { media } from './media';

const Typography = createGlobalStyle`    
    @font-face {
        font-family: 'Suisse';
        src: url(${SuisseIntLightWoff2}) format('woff2'),
            url(${SuisseIntLightWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Suisse';
        src: url(${SuisseIntRegularWoff2}) format('woff2'),
            url(${SuisseIntRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Suisse';
        src: url(${SuisseIntMediumWoff2}) format('woff2'),
            url(${SuisseIntMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Suisse';
        src: url(${SuisseIntBoldWoff2}) format('woff2'),
            url(${SuisseIntBoldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
    }

    body{
        font-family: 'Suisse', 'Times New Roman', 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .h1,
    .blog-article-body h1 {
        font-weight: 500;
        font-size: 60px;
        line-height: 106%;
        letter-spacing: -1px;
        @media ${media.smUp} {
            font-size: 80px;
        }
        @media ${media.lgUp} {
            font-size: 95px;
        }
    }

    .h1 {
        h1 {
            font-size: 48px;
            line-height: 56px;
            letter-spacing: -0.8px;
        @media ${media.smUp} {
            font-size: 80px;
            line-height: 85px;
            letter-spacing: -0.84px;
        }
        @media ${media.lgUp} {
            font-size: 95px;
            line-height: 105px;
            letter-spacing: -1px;
        }
        }
    }

    eyebrow, .eyebrow {
        font-size: 12px;
        line-height: 200%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-weight: 500;
        &.line {
            position: relative;
            &::after {
                position: absolute;
                content: '';
                width: 25px;
                height: 1px;
                margin: 5px 0 0 5px;
                background-color: currentColor;
            }
        }
    }

    .eyebrow--large {
        font-weight: 400;
        font-size: 24px;
        line-height: 48px;
        text-transform: uppercase;
    }

    .h2,
    .blog-article-body h2 {
        font-weight: 400;
        font-size: 60px;
        line-height: 100%;
        letter-spacing: -1px;
        @media ${media.smUp} {
            font-size: 60px;
        }
        @media ${media.lgUp} {
            font-size: 80px;
        }
    }
    .h3,
    .blog-article-body h3 {
        font-weight: normal;
        font-weight: 400;
        font-size: 56px;
        line-height: 64px;
        letter-spacing: -0.75px;
        @media ${media.smUp} {
            font-size: 56px;
        }
        @media ${media.lgUp} {
            font-size: 56px;
            font-weight: 500;
        }
        &.normal {
            font-weight: normal;
        }
    }
    .h4,
    .blog-article-body h4 {
        font-weight: 400;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.75px;
    }
    .h5,
    .richtext--holder h5,
    .blog-article-body h5 {
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.75px;
        @media ${media.smUp} {
         font-size: 28px;
        }
        @media ${media.lgUp} {
         font-size: 32px;
        }
    }
    .h6,
    .richtext--holder h6,
    .blog-article-body h6 {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.75px;
    }

    .body--large {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 38px;
        @media ${media.smUp} {
        }
        @media ${media.lgUp} {
            font-size: 26px;
            line-height: 44px;
        }
    }
    .body--small {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        @media ${media.smUp} {
        }
        @media ${media.lgUp} {
            font-size: 18px;
        }
    }
    p {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        @media ${media.smUp} {
          letter-spacing: 0.02em;
        }
        @media ${media.lgUp} {
          line-height: 170%;
          letter-spacing: 0.01em;
        }
    }
    p.small {
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.01em;
        @media ${media.smUp} {
          letter-spacing: 0.04em;
        }
        @media ${media.lgUp} {
          line-height: 170%;
          letter-spacing: 0.02em;
        }
        a:not(.o-hover):not(.menu--link) {
            border-bottom: 1px solid var(--grey-6);
            &:hover, &:focus {
                border-color: transparent;
            }
        }
    }
    span.small {
        font-size: 12px;
    }
    p.tiny {
        font-size: 8px;
        @media ${media.smUp} {
            font-size: 10px;
        }
    }
    .quote {
      font-weight: normal;
    }
    caption, .caption {
        font-weight: 500;
        font-size: 10px;
        line-height: 120%;
        letter-spacing: 0.01em;
        @media ${media.smUp} {
          line-height: 140%;
        }
        @media ${media.lgUp} {
          line-height: 120%;
        }
        a {
            &:hover, &:focus {
                opacity: 0.9;
            }
        }
    }
    sup {
        font-size: 65%;
    }
    .md {
        font-weight: 500;
    }
    .o-hover {
        &:hover, &:focus {
            opacity: 0.9;
        }
    }
    cta, .cta {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        text-decoration-line: underline;
    }
    .stat {
        font-size: 32px;
        line-height: 100%;
        @media ${media.smUp} {
            font-size: 48px;
        }
        @media ${media.lgUp} {
            font-size: 60px;
        }
        span {
            font-weight: normal;
            font-size: 14px;
            line-height: 160%;
            letter-spacing: 0.04em;

            &.stat--md {
                position: relative;
                top: -5px;
                font-size: 28px;
                @media ${media.smUp} {
                    top: -10px;
                    font-size: 48px;
                }
            }
        }
    }
    .ul--specs,
    .specs--content ul {
        font-size: 14px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: var(--grey-5);
        @media ${media.smUp} {
            font-size: 14px;
            line-height: 140%;
        }
        @media ${media.lgUp} {
            font-size: 14px;
            line-height: 140%;
        }
        li + li {
            margin-top: 7px;
        }
        li {
            position: relative;
            margin-bottom: 15px;
            padding-left: 20px;
            &::before {
                position: absolute;
                top: 3px;
                left: 0;
                content: '';
                width: 7px;
                height: 7px;
                border-radius: 100%;
                background: var(--main);
                border: 1px solid var(--main);
            }

            &.open {
                &::before {
                    background: transparent;
                }
            }
        }
        li:last-child {
          margin-bottom: 0;
        }
        &.color--tertiary {
            li::before {
                background: var(--tertiary);
                border: 1px solid var(--tertiary);
            }
        }
    }

    .heading--num {
        position: relative;
        &::before {
            position: absolute;
            content: attr(data-num);
            left: -120px;
            width: 100px;
            text-align: right;
        }
    }
`;

export default Typography;
