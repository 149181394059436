import { LoadingBar } from '@csscompany/csds-components';
import {
  Player,
  PlayerControllerProps,
  PlayerProps,
  useIsMoving,
} from '@publicss/components';
import { useOnClickOutside } from '@publicss/components/hooks';
import { Close, Pause, Play, VolumeDown, VolumeMuted } from '@publicss/icons';
import { secondsToMinutes } from '@publicss/utils';
import cx from 'classnames';
import React, { forwardRef, useCallback, useState } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';

import { media } from '../../styles/media';
import { RoundButton, PillButton } from './Button';
import { InputRange } from './Form';

const Container = styled.section<{ isOpen: boolean }>`
  .player-controller {
    opacity: 1;
    transition: all 1s ease-in;

    &.--hide {
      opacity: 0;
      transition: all 1s ease-out;
    }
  }
  .player-btn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media ${media.smUp} {
      bottom: 50px;
      left: 50px;
      top: auto;
      transform: none;
    }
  }
  .player-btn-volume {
    transition: all 200ms linear;
    width: 50px;
    min-width: 50px;

    input {
      display: none;
    }

    &.--open {
      width: 170px;
      input {
        display: inherit;
      }
    }
  }

  video {
    width: 100vw;
    max-height: calc(100vh - 108px) !important;

    object-fit: cover;
    @media ${media.smUp} {
      width: calc(100vw - 108px) !important;
    }
  }

  [role='progressbar'] {
    background: #fcf5ed;
    transition: transform 1s linear 0s;
  }
`;

interface FancyPlayerProps extends PlayerProps {
  onClose?: () => void;
}

function VolumeButton({ muted, setVolume, volume }) {
  const [isOpen, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const VolumeIcon = muted || volume === 0 ? VolumeMuted : VolumeDown;
  const onVolume = (e) => setVolume(Number(e?.target?.value));
  const iconProps = {
    height: '20px',
    width: '20px',
  };
  const ref = useRef(null);

  const handleClickOutside = () => {
    onClose();
  };

  // we needed this because the player was buggy when it came to onBlur
  useOnClickOutside(ref, handleClickOutside);
  return (
    <>
      <PillButton
        ref={ref}
        className={cx('player-btn-volume !flex justify-center gap-1', {
          '--open': isOpen,
          'px-3': isOpen,
        })}
        onClick={onOpen}>
        <div className={isOpen ? 'pr-3' : 'hidden'}>
          <InputRange
            min={0}
            max={100}
            autoFocus
            disabled={muted}
            value={volume}
            onChange={onVolume}
            onBlur={onClose}
          />
        </div>

        <VolumeIcon {...iconProps} />
      </PillButton>
    </>
  );
}

export function FancyPlayerController({
  playing,
  volume,
  muted,
  setVolume,
  togglePlay,
  onClose,
}: FancyPlayerProps & PlayerControllerProps) {
  const PlayingIcon = playing ? Pause : Play;
  const [isMoving, onMove] = useIsMoving(3);
  const hideController = playing && !isMoving;
  const close = () => {
    playing && togglePlay();
    onClose && onClose();
  };

  return (
    <div
      className={cx('player-controller absolute inset-0', {
        '--hide': hideController,
      })}
      onMouseMove={onMove}>
      <div className="absolute right-5 top-5 grid grid-flow-col gap-2">
        {!!playing && (
          <VolumeButton volume={volume} setVolume={setVolume} muted={muted} />
        )}
        {onClose && (
          <PillButton className="player-btn-close" onClick={close}>
            <Close height="30px" width="100%" />
          </PillButton>
        )}
      </div>

      <div className="player-btn absolute">
        <RoundButton
          className="player-btn-play"
          variant="play-dark"
          size="play-button"
          onClick={togglePlay}>
          <PlayingIcon width="100%" height="30px" />
        </RoundButton>
      </div>
    </div>
  );
}

const ProgressBar = ({ progress, playedSeconds, totalSeconds }) => {
  return (
    <div className="relative text-xs text-white-3">
      <small className="absolute -top-5 left-3">
        {secondsToMinutes(playedSeconds)}
      </small>
      <small className="absolute -top-5 right-3">
        -{secondsToMinutes(totalSeconds - playedSeconds)}
      </small>
      <LoadingBar progress={progress} />
    </div>
  );
};

export const FancyPlayer = forwardRef((props: FancyPlayerProps, ref) => {
  const [progressProps, setProgress] = useState({
    progress: 0,
    totalSeconds: 0,
    playedSeconds: 0,
  });
  const onProgress = useCallback(({ loaded, loadedSeconds, playedSeconds }) => {
    const totalSeconds = loadedSeconds / loaded;
    const progress = playedSeconds / totalSeconds;
    setProgress({ progress, playedSeconds, totalSeconds });
  }, []);

  return (
    <Container {...props} ref={ref}>
      <Player
        {...props}
        webkit-playsinline
        playsinline
        onProgress={onProgress}
        Controller={FancyPlayerController}
        width="100%"
        height="auto"
      />
      <ProgressBar {...progressProps} />
    </Container>
  );
});
