import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import * as React from 'react';
import { useEffect } from 'react';
import * as ReactDOM from 'react-dom';

import {
  // Generic packages should not depend on specific sites
  MaxWidthContainer,
  PageGrid,
} from '../../../sites/css-com/src/components/ui';

const Child = () => {
  return (
    <MaxWidthContainer className="z-10 col-span-full row-start-1 flex h-[100vh] flex-col items-center !py-0">
      <PageGrid
        className={
          'flex h-full auto-rows-min flex-col content-center items-center'
        }>
        <div className="align-center col-span-full grid sm:col-start-2 sm:col-end-11">
          <div
            id="page-loader-heading"
            className="text-brown-2 fade-up leadinh-[48px] sm:track-[-0.84px] self-center text-[38px] font-medium tracking-[-0.42px] opacity-0 sm:text-[80px] sm:leading-[85px] lg:text-[95px] lg:leading-[105px] lg:tracking-[-1px]">
            We Serve Those Who Serve Others
          </div>
          <div
            id="page-loader-paragraph"
            className="body--small text-brown-2 mt-[30px] max-w-[475px] opacity-0 lg:mt-[50px]">
            At City Storage Systems we’re creating opportunities and
            rejuvenating communities through real estate, technology and food.
          </div>
        </div>
      </PageGrid>
    </MaxWidthContainer>
  );
};

export const usePageLoader = () => {
  useEffect(() => {
    const handleVideoLoad = () => {
      const initialOverlay = document.querySelector('#initial-overlay'); // hide initial overlay
      if (initialOverlay) {
        initialOverlay.style.display = 'none';
      }
      tl.to('#page-loader', {
        // animate page loader out
        y: '-100%',
        ease: CustomEase.create('custom', 'M0,0 C0.736,-0.002 0.266,1 1,1 '),
        duration: 1,
        onStart: () => {
          overlay?.classList.add('loaded');
          /*
          setTimeout(() => {
            gsap.to('#page-loader-heading', {
              color: '#FCF5ED',
            });
            gsap.to('#page-loader-paragraph', {
              color: '#FCF5ED',
            });
          }, 250); */
        },
        onComplete: () => {
          tl.to('#page-loader', {
            display: 'none',
          });
        },
        onComplete: () => {
          tl.to('#page-loader', {
            display: 'none',
          });
        },
      })
        .to(
          '.block-hero-text-heading',
          {
            yPercent: 0,
            delay: 0.05,
          },
          '<+=.3'
        )
        .to(
          '.block-hero-text-paragraph',
          {
            yPercent: 0,
            delay: 0.05,
          },
          '<'
        )
        .to('html', {
          overflow: 'unset',
        })
        .set('#page-loader-heading', {
          autoAlpha: 0,
        })
        .set('#page-loader-paragraph', {
          autoAlpha: 0,
        });
    };

    let video = document.getElementById('block-hero-text-video');
    const overlay = document.querySelector('#page-loader');

    if (!overlay) {
      //create it if it doesnt exist
      const body = document.querySelector('body');
      const pageloader = document.createElement('div');
      pageloader.setAttribute('id', 'page-loader');
      body?.appendChild(pageloader);
    }
    const tl = gsap.timeline();
    if (overlay?.style?.display === 'none') return;

    // video is not loaded

    // bring up our overlay

    tl.set('html', {
      overflow: 'hidden',
    }).set('#page-loader', {
      y: '0%',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      zIndex: 100, // change this to 100 to hide nav
      background: '#F5ECE0',
      left: 0,
      yPercent: 0,
    });

    // render our react component
    ReactDOM.render(<Child />, document.getElementById('page-loader'), () => {
      video = document.getElementById('block-hero-text-video');
      tl.fromTo(
        '#page-loader-heading',
        { autoAlpha: 0 },
        {
          // fade in the heading
          delay: 0.5,
          autoAlpha: 1,
          duration: 0.6,
        }
      )
        .fromTo(
          '#page-loader-paragraph',
          { autoAlpha: 0 },
          {
            // fade in the paragraph
            delay: 0.3,
            autoAlpha: 1,
            duration: 0.6,
          }
        )
        .set('.block-hero-text-heading', {
          yPercent: 40,
        })
        .set('.block-hero-text-paragraph', {
          yPercent: 40,
          onComplete: () => {
            video = document.getElementById('block-hero-text-video');
            if (video.readyState === 4) {
              handleVideoLoad();
            }
          },
        });
    });
  }, []);
};

export default usePageLoader;
