import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from 'react';

export const useLazyLoadElements = (selector) => {
  useEffect(() => {
    // i needed this otherwise gatsby link <> scrolltrigger was breaking
    ScrollTrigger.refresh();
    const fadeUp = document.querySelectorAll(selector);
    fadeUp.forEach(function (element) {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.7,
        opacity: '0',
        y: '20%',
      });
    });
  }, []);
};
