import React from 'react';

import Block404 from '../blocks/Block404';
import {
  getHomeRedirect,
  useClientRedirects,
} from '../hooks/useClientRedirects';

export function Page404({ pageContext }) {
  const { redirects, langs } = pageContext;
  const homePath = getHomeRedirect(redirects, langs);
  useClientRedirects(redirects, langs);

  return <Block404 homePath={homePath} />;
}
