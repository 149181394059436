import * as React from 'react';
import { forwardRef } from 'react';

const SvgChevron = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.314 1.657 9.899.243 5.657 4.485 1.414.243 0 1.657l4.243 4.242 1.414 1.415 5.657-5.657Z"
      fill="#E09164"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgChevron);
export default ForwardRef;
