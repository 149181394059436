import { usePageContext } from '@publicss/components';
import React from 'react';

import { Global_Variables } from '../../types/prismic';
import { MaxWidthContainer, PageGrid } from './ui';

interface FooterProps {
  hasCareerForm?: boolean;
  id?: string;
}

const Footer: React.FC<FooterProps> = ({ id }) => {
  const global = usePageContext().global as Global_Variables;

  return (
    <div className="bg-black-2 py-20" id={id}>
      <MaxWidthContainer>
        <PageGrid>
          <div className="col-span-4 sm:col-start-2">
            <h2 className="eyebrow mb-3 text-white-2">Products</h2>
            <div className="text-base leading-loose">
              {global?.footer_nav?.map(({ title, url }, index) => (
                <a
                  className="block text-brown-1"
                  href={url as string}
                  key={index}>
                  {title}
                </a>
              ))}
            </div>
          </div>
          <div className="col-span-4">
            <h2 className="eyebrow mb-3 text-white-2">Contact</h2>
            <div className="text-base leading-loose">
              <a
                className="block text-brown-1"
                href={global.linkedin_link as string}
                target="_blank">
                LinkedIn
              </a>
              <a
                className="block text-brown-1"
                href={`mailto:${global.contact_email as string}`}>
                Email
              </a>
              <i className="mt-10 block text-xs font-medium text-brown-2">
                CONFIDENTIAL
              </i>
            </div>
          </div>
        </PageGrid>
      </MaxWidthContainer>
    </div>
  );
};

export default Footer;
