import {
  useToggleClassName,
  useMediaQuery,
  usePageContext,
} from '@publicss/components';
import { gsap } from 'gsap';
import React from 'react';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { theme, media } from '../styles/media';
import { SmallButtonIcon, TextLink } from './ui';

const VideoOverlay = styled.div`
  grid-column: 1/-1;
  grid-row: 1;
  z-index: 0;
  &:before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0);
    opacity: 75%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const Container = styled.div<{ open: boolean }>`
  transition: all 0.6s ease;
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr;
  opacity: 1;
  background: var(--black-2);
  @media ${media.mdDown} {
    ${(props) => {
      if (props.open) {
        return css`
          z-index: 20;
          left: 0px;
          width: calc(100%);
          max-height: 100vh;
          height: calc(100vh);
          overflow-y: scroll;
          max-width: 100%;
        `;
      } else
        return css`
          left: 0px;
          z-index: 10;
          width: 100%;
          max-height: 100%;
          z-index: -1;
          opacity: 0;
          overflow: hidden;
        `;
    }}
  }

  @media ${media.smUp} {
    ${(props) => {
      if (props.open) {
        return css`
          grid-template-columns: 1fr 1fr;
          top: 0px;
          z-index: 20;
          left: 80px;
          width: calc(100%-5em);
          max-height: calc(100%);
          background: var(--black-2);
          max-width: 100%;
          overflow-y: scroll;
          overflow-x: hidden;
        `;
      } else {
        return css`
          opacity: 0;
          grid-template-columns: 1fr 1fr;
          left: 80px;
          bottom: 0px;
          top: 0px;
          z-index: -1;
          overflow: hidden;
        `;
      }
    }}
  }
`;

const StyledTextLink = styled(TextLink)`
  font-size: 10px;
  color: var(--brown-1);
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

const StyledVideo = styled.video`
  object-fit: fill;
  min-height: 100vh;
  height: 100%;
`;

const handleNavBackgroundOnWeb = (open, isMobile) => {
  const nav = document.querySelector<HTMLElement>('#navbar');
  if (nav) {
    nav.style.transition = 'all 500ms linear';
    if (open && !isMobile) {
      nav.style.background = 'var(--black-2)';
      nav.style['mix-blend-mode'] = 'unset';
    } else {
      nav.style.background = 'unset';
      nav.style['mix-blend-mode'] = 'difference';
    }
  }
};

const NavMenu = ({ open, setOpen }) => {
  const pageContext = usePageContext();
  const { global } = pageContext;
  const isMobile = useMediaQuery(`(max-width:${theme.screens.sm})`);
  useEffect(() => {
    handleNavBackgroundOnWeb(open, isMobile);
  }, [open]);
  useToggleClassName('body', 'overflow-hidden', open);
  useEffect(() => {
    if (open) {
      const links = gsap.utils.toArray('.link');
      const last = gsap.utils.toArray('#last');
      const tl = gsap.timeline();
      tl.from(links, {
        delay: 0.3,
        opacity: 0,
        stagger: 0.1,
      })
        .from(
          '.menu-video',
          {
            delay: 0.55,
            opacity: 0.8,
          },
          0
        )
        .from(
          last,
          {
            opacity: 0,
            delay: 0.65,
          },
          0
        );
    }
  }, [open]);
  return (
    <Container open={open} className="grid">
      <div className="relative col-span-full sm:col-span-1">
        <div className="pb-8">
          <div
            id="last"
            className="mt-20 flex pl-8 pr-12 sm:mt-12 sm:justify-end sm:pl-0 ">
            <a href="mailto:help@csscompany.com">
              <SmallButtonIcon iconText={'?'} buttonText="ASK QUESTION" />
            </a>
          </div>
          <div className="px-8 pt-20 sm:px-20 lg:pt-30 xl:px-40">
            <ul>
              {global?.footer_nav?.map(({ title, url }, index) => (
                <li
                  key={index}
                  className="link h4 !font-medium text-brown-2 hover:text-white-1"
                  onClick={() => setOpen(false)}>
                  <a href={url} key={index}>
                    {title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <ul
            className={
              'grid grid-cols-[max-content_max-content] justify-between pl-8 pr-8 pt-30 sm:justify-end sm:pl-0 sm:pr-12 tall:absolute tall:bottom-[60px] tall:right-[50px]'
            }>
            <li onClick={() => setOpen(false)}>
              <StyledTextLink
                color="brown-1"
                to="/privacy-policy"
                className={'delay-900 nowrap sm:pr-30'}>
                PRIVACY POLICY
              </StyledTextLink>
            </li>
            <li onClick={() => setOpen(false)}>
              <StyledTextLink
                color="brown-1"
                className={'nowrap'}
                to="/terms-of-service">
                TERMS & CONDITIONS
              </StyledTextLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="relative hidden sm:block">
        <VideoOverlay className="menu-video h-full">
          <StyledVideo
            src={global?.hamburger_menu_video?.url}
            autoPlay
            loop
            muted
          />
        </VideoOverlay>
      </div>
    </Container>
  );
};

export default NavMenu;
