import { useEffect } from 'react';

export function useToggleClassName(selector, className, isOn = true) {
  useEffect(() => {
    const elements = document.querySelectorAll(selector);

    elements.forEach((el) => {
      isOn ? el.classList.add(className) : el.classList.remove(className);
    });
  }, [selector, className, isOn]);
}

export default useToggleClassName;
