import styled, { css } from 'styled-components';

interface DividerProps {
  color:
    | 'primary-1'
    | 'brown-1'
    | 'brown-2'
    | 'black-2'
    | 'black-2a1'
    | 'white-1'
    | 'white-2'
    | 'black';

  variant?: 'solid' | 'dashed';
  /**
   *
   * 0.07 0.15 1.00 Opacity
   */
  opacity?: 'low' | 'medium' | 'high';
  size?: 'small' | 'medium' | 'large';
}

function getSize({ size }) {
  switch (size) {
    case 'small':
      return '1px';

    case 'medium':
      return '5px';

    case 'large':
    default:
      return '10px';
  }
}

function getOpacity({ opacity }) {
  switch (opacity) {
    case 'low':
      return '0.07';
    case 'medium':
      return '0.15';

    case 'high':
    default:
      return '1';
  }
}

function getColor({ color }) {
  return `var(--${color})`;
}

export const Divider = styled.hr<DividerProps>`
  width: 100%;
  /**
   * default to black
   */
  border: none;
  background: none;
  opacity: ${getOpacity};

  ${(props: DividerProps) => {
    const color = getColor(props);
    const size = getSize(props);

    switch (props.variant) {
      case 'dashed':
        return css`
          background-image: linear-gradient(
            -45deg,
            ${color} 10%,
            transparent 25%,
            transparent 50%,
            ${color} 50%,
            ${color} 75%,
            transparent 75%,
            transparent
          );
          background-size: 0.25em 0.25em;
          height: ${size};
          width: 100%;
        `;

      case 'solid':
      default:
        return `border-top: solid ${size} ${color};`;
    }
  }}
`;

export default Divider;
