import * as React from 'react';
import { forwardRef } from 'react';

const SvgVolumeMuted = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}>
    <path d="M0 5v6h4l5 5V0L4 5H0Z" fill="#E09164" />
  </svg>
);

const ForwardRef = forwardRef(SvgVolumeMuted);
export default ForwardRef;
