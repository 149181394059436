import React from 'react';
import styled from 'styled-components';

interface ButtonIconProps {
  iconText: string | React.ReactNode;
  buttonText: string;
  as?: React.ElementType;
}

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  width: fit-content;
  background-color: transparent;
  padding: 10px 12px;
  border: 1px solid var(--small-button-icon-passive);
  border-radius: 25px;
  color: var(--brown-1);
  &:hover {
    color: rgba(150, 127, 120, 0.8);
    border: 1px solid var(--small-button-icon-hover);
  }
  &:active {
    color: var(--brown-1);
    border: 1px solid var(--small-button-icon-active);
  }
`;

export const SmallButtonIcon: React.FC<ButtonIconProps> = ({
  iconText,
  buttonText,
  as,
  ...props
}) => {
  return (
    <Button as={as} {...props}>
      <div className="mr-[10px] grid h-[30px] w-[30px] content-center justify-center rounded-button-icon bg-white-2/[0.07]">
        <div className="text-[12px] !font-medium">{iconText}</div>
      </div>
      <span className="text-[12px] !font-medium">{buttonText}</span>
    </Button>
  );
};

export default SmallButtonIcon;
