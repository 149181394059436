module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-web-font-loader@1.0.4/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":[]}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@3.15.0_gatsby@5.11.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NH4J2FV","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@3.15.0_gatsby@5.11.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-42YLZ6QJNP"],"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-facebook-pixel@1.0.8/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"3074077356176812"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.11.0_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@4.9.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
