import styled from 'styled-components';

import { media } from '../../styles/media';

export const PageGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  @media ${media.smUp} {
    grid-column-gap: 40px;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  @media ${media.lgUp} {
    max-width: 1150px;
    column-gap: 50px;
  }
`;

export default PageGrid;
