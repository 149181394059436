import React from 'react';
import styled from 'styled-components';

interface WatermarkProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
}

const Div = styled.div<WatermarkProps>`
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    z-index: 1;
    padding: 2em;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    pointer-events: none;
    transform: rotate(-45deg);
    content: '${({ text }) => text}';

    opacity: 0.2;
    line-height: 33vw;
    word-spacing: 2em;
    word-break: break-all;
    color: #fff;
  }
`;

export const Watermark: React.FC<WatermarkProps> = ({ text, ...props }) => (
  <Div {...props} text={(text + ' ').repeat(1000)} />
);
