import { useState, useEffect } from 'react';

export function useEscapableToggle(initialState: boolean) {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    const escapeHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setState(false);
      }
    };
    window.addEventListener('keydown', escapeHandler);
    return () => {
      window.removeEventListener('keydown', escapeHandler);
    };
  }, [setState]);

  return [state, setState];
}
