import styled from 'styled-components';

function getProgress({ value, min, max }) {
  const percent = Math.round(((Number(value) - min) / (max - min)) * 100);
  return `${percent}%`;
}

function getOpacity({ disabled }) {
  return disabled ? '0.6' : '1';
}

export const InputRange = styled.input.attrs({ type: 'range' })`
  --thumbSize: 12px;
  --trackSize: 12px;
  --thumbBg: var(--primary-1);
  --trackBg: rgba(36, 33, 32, 0.1);
  --progressBg: var(--primary-1);
  --webkitProgressPercent: ${getProgress}; // webkit hack

  appearance: none;
  height: var(--thumbSize);
  opacity: ${getOpacity};
  width: 100%;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  /* Thumb */
  &::-webkit-slider-thumb {
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border: none;
    //box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }

  &::-ms-thumb,
  &::-moz-range-thumb {
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }

  /* Track */
  &::-webkit-slider-runnable-track {
    height: var(--trackSize);
    background-image: linear-gradient(
      90deg,
      var(--progressBg) var(--webkitProgressPercent),
      var(--trackBg) var(--webkitProgressPercent)
    );
  }

  &::-ms-track,
  &::-moz-range-track {
    height: var(--trackSize);
    background-color: var(--trackBg);
  }

  /* Progress */
  &::-ms-fill-lower,
  &::-moz-range-progress {
    height: var(--trackSize);
    background-color: var(--progressBg);
  }
`;
