import * as React from 'react';
import { forwardRef } from 'react';

const SvgPause = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 21V9h3v12h-3ZM17 21V9h3v12h-3Z"
      fill="#E09164"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgPause);
export default ForwardRef;
