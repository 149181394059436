import { useState, useRef } from 'react';

export type UseIsMoving = [boolean, () => void];

export function useIsMoving(delayInSeconds: number): UseIsMoving {
  const [isMoving, setIsMoving] = useState(false);
  const timeout = useRef<any>(undefined);
  const wait = delayInSeconds * 1000;

  return [
    isMoving,
    () => {
      setIsMoving(true);

      // Debounce and avoing stacking
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => setIsMoving(false), wait);
    },
  ];
}

export default useIsMoving;
