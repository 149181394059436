import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --main: ${({ global }) => global?.color_primary || '#C74741'};
    --accent-1: ${({ global }) => global?.color_accent_1 || '#921D22'};
    --accent-2: ${({ global }) => global?.color_accent_2 || '#FED2CE'};
    --black: #000000;
    --black-1: #2F2B2A;
    --black-2: #242120;
    --black-2a1: #2421201A;
    --white: #ffffff;
    --white-1: #FFFCF7;
    --white-2: #FCF5ED;
    --white-3: #F9F1E6;
    --white-4: #F5ECE0;
    --primary-1: #E09164;
    --primary-1-hover: rgba(224, 145, 100, 0.9);
    --primary-1-active: rgba(224, 145, 100, 0.85);
    --primary-2: #70351E;
    --primary-3: #e4bd75;
    --small-button-icon-active: rgba(150, 127, 120, .37);
    --small-button-icon-hover: rgba(150, 127, 120, .27);
    --small-button-icon-passive: rgba(150, 127, 120, .1);
    --rounded-button-hover: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), var(--white-3);
    --rounded-button-active:  linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), var(--white-3);
    --brown-1: #967F78;
    --brown-2: #62514C;
    --transparent: transparent;
 }

  * {
    box-sizing: border-box;
  }

  html {
    overflow-y: scroll;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
  }

  body, html {
    min-height: 100vh;
    overflow-x: hidden; /* Prevent horizontal scrollbar caused by 100vw Layout */
  }
  body {
    position: relative;
    overflow: overlay;

  }

  .wrapper {
      position: relative;
      min-height: 100vh;
  }

  img {
    max-width: 100%;
  }

  a {
    transition: all .15s linear;
    cursor: pointer;
    text-decoration: none;
  }


  // Fancy ScrollBar
  *::-webkit-scrollbar {
    width: 10px;
    margin: 0;
    padding: 0;
  }

  *::-webkit-scrollbar-track {
    background: rgba(36,33,32, 0.07);
  }

  *::-webkit-scrollbar-thumb {
    background: var(--primary-1);
    outline: none;
  }
  .tl-wrapper  {
    overflow: hidden;
  }
`;

export default GlobalStyles;
