// Tailwind Reference
// https://nerdcave.com/tailwind-cheat-sheet
module.exports = {
  future: {
    removeDeprecatedGapUtilities: true,
  },
  content: [
    '../../packages/components/**/*.{js,jsx,ts,tsx}',
    './src/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    screens: {
      smDown: { max: '720px' },
      sm: '720px',
      md: '1280px',
      lg: '1800px',
      xl: '1800px',
      tall: { raw: '(min-height: 900px) and (min-width: 720px)' },
    },
    extend: {
      colors: {
        'black-1': '#2F2B2A',
        'black-2': '#242120',
        'white-1': '#FFFCF7',
        'white-2': '#FCF5ED',
        'white-3': '#F9F1E6',
        'white-4': '#F5ECE0',
        'primary-1': '#E09164',
        'primary-2': '#70351E',
        'primary-3': '#E4BD75',
        'brown-1': '#967F78',
        'brown-2': '#62514C',
        'brown-3': '#393433',
        main: 'var(--main)',
        'accent-1': 'var(--accent-1)',
        'accent-2': 'var(--accent-2)',
      },
      borderRadius: {
        'button-icon': '25px',
        'player-pill': '100px',
        'xl-button': '1000px',
      },
      spacing: {
        15: '60px',
        30: '120px',
        50: '200px',
      },
      maxHeight: {
        'block-image-compare': '800px',
      },
      maxWidth: {
        'block-image-compare': '1550px',
      },
      gridTemplateColumns: {
        'footer-items-web': 'repeat(2, 250px)',
        'footer-items-tablet': 'repeat(2, 240px)',
        'footer-items-mobile': 'repeat(2, 116px)',
      },
    },
  },
  variants: {},
  plugins: [require('@tailwindcss/aspect-ratio')],
};
