import { navigate } from 'gatsby';
import { gsap } from 'gsap';
import { useEffect } from 'react';

export const useTransitionLinks = () => {
  useEffect(() => {
    const elements = gsap.utils.toArray('a');
    for (let i = 0; i < elements.length; i += 1) {
      const element = elements[i];
      element.addEventListener(
        'click',
        (e) => {
          e.preventDefault();
          const href = e.currentTarget.getAttribute('href');
          const tl = gsap.timeline();
          tl.to('#transition', {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: '#F5ECE0',
            width: '100%',
            height: '0%',
            zIndex: 100,
            yPercent: -100,
          })
            .to('#transition', {
              yPercent: 0,
              height: '100%',
              duration: 1,
            })
            .to('#transition', {
              onStart: () => {
                navigate(href);
              },
            })
            .to('#transition', {
              delay: 0.3,
              height: '0%',
            });
        },
        false
      );
    }
  }, []);
};
