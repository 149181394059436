import React from 'react';
import styled, { css } from 'styled-components';

interface ButtonToggleProps {
  variant: 'primary' | 'secondary' | 'select' | 'faq';
  color?: 'light' | 'dark';
  focused?: boolean;
  as?: React.ElementType;
}

export const ButtonToggle = styled.button<ButtonToggleProps>`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
  }
  ${(props) => {
    if (props.variant === 'primary' && props.color === 'dark') {
      return css`
        background-color: var(--black-1);
        opacity: 0.93;
        path {
          fill: var(--brown-1);
        }
        &:hover {
          opacity: 0.95;
        }
        &:active {
          opacity: 0.96;
        }
      `;
    }
    if (props.variant === 'secondary' && props.color === 'dark') {
      return css`
        background-color: transparent;
        opacity: 0.93;
        path {
          fill: var(--primary-1);
        }
        &:hover {
          background-color: var(--black-1);
          opacity: 0.97;
        }
        &:active {
          background-color: var(--black-1);
          opacity: 0.95;
        }
      `;
    }
    if (props.variant === 'primary' && props.color === 'light') {
      return css`
        background-color: var(--white-2);
        path {
          fill: var(--primary-1);
        }
        &:hover {
          background-color: var(--white-2);
          opacity: 0.75;
        }
        &:active {
          background-color: var(--white-2);
          opacity: 0.6;
        }
      `;
    }

    if (props.variant === 'select') {
      return css`
        background-color: transparent;
        ${(props) => {
          if (props.focused) {
            return css`
              background-color: var(--black-1);
              opacity: 0.95;
            `;
          }
        }}
        z-index: 10;
        display: grid;
        path {
          fill: var(--primary-1);
        }
        g path {
          fill: transparent;
        }

        &:hover {
          background-color: var(--black-1);
          opacity: 0.97;
        }

        &:active {
          background-color: var(--black-1);
          opacity: 0.95;
        }
      `;
    }

    if (props.variant === 'faq') {
      return css`
        background: var(--white-4);
        path {
          fill: var(--brown-1);
        }
        &:hover {
          opacity: 0.75;
        }
        &:active {
          opacity: 0.6;
        }
      `;
    }
  }}
`;

export default ButtonToggle;
