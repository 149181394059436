import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import { TextLink } from './ui';

const Container = styled.nav`
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  color: var(--white-1);
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #aaa4;

  .nav-list {
    display: grid;
    grid-template-columns: min-content 1fr;
    mix-blend-mode: difference;
    justify-items: flex-end;
    align-items: center;
    height: 100%;
  }
  .nav-item {
    transform-origin: 0%;
    white-space: nowrap;
    margin-left: 2em;
    margin-right: 2em;
    vertical-align: text-top;
  }
  path {
    fill: var(--white-2);
  }
  g > path {
    fill: transparent;
  }
`;

const NavMobile = ({ className }) => {
  return (
    <Container
      id="navbar"
      className={classNames('!block sm:!hidden', className)}>
      <ul className="nav-list text-lg !font-medium uppercase">
        <li className="nav-item top-[10em]">
          <TextLink className="!text-xs leading-5 tracking-[0.03125em]" to="/">
            City Storage Systems &reg;
          </TextLink>
        </li>
      </ul>
    </Container>
  );
};

export default NavMobile;
