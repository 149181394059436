import React from 'react';
import styled from 'styled-components';

import { TextLink } from './ui';

const Container = styled.nav`
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  bottom: 0;
  mix-blend-mode: difference;
  border-right: 1px solid #33302f;
  color: var(--white-1);
  width: 5rem;

  .nav-list {
    position: absolute;
    bottom: 0;
    top: 0;
  }

  .nav-item {
    transform-origin: 0%;
    transform: rotate(-90deg);
    position: absolute;
    white-space: nowrap;
    margin: 2em;
    vertical-align: text-top;
  }

  .nav-item-last {
    bottom: 0;
  }

  path {
    fill: var(--white-2);
  }
`;

const Nav = ({ open, setOpen }) => {
  return (
    <Container id="navbar" className="!hidden sm:!block">
      <ul className="nav-list text-lg !font-medium uppercase">
        <li className="nav-item top-[10em]">
          <TextLink
            className="!text-xs leading-5 tracking-[0.03125em]"
            to="/"
            onClick={() => {
              if (open) setOpen(!open);
            }}>
            City Storage Systems &reg;
          </TextLink>
        </li>
      </ul>
    </Container>
  );
};

export default Nav;
