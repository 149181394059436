import { usePageContext } from '@publicss/components';
import React from 'react';
import { Helmet } from 'react-helmet';

export default function GlobalMeta() {
  const { global, url } = usePageContext();
  const isBlog = url?.includes('/blog/');
  if (!global) return <></>;
  const shareImg = global?.social_share_image?.url;
  return (
    <Helmet>
      {global.favicon?.url && (
        <link
          href={global.favicon.url}
          rel="shortcut icon"
          type="image/x-icon"
        />
      )}
      {shareImg && !isBlog && <meta property="og:image" content={shareImg} />}
      {shareImg && !isBlog && (
        <meta property="og:image:secure_url" content={shareImg} />
      )}
      {shareImg && !isBlog && <meta property="og:image:width" content="1200" />}
      {shareImg && !isBlog && <meta property="og:image:height" content="630" />}
      <meta name="viewport" content="width=device-width, user-scalable=no" />
      {shareImg && <meta name="twitter:image" content={shareImg} />}
    </Helmet>
  );
}
