import * as React from 'react';
import { forwardRef } from 'react';

const SvgVolumeDown = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11V5h4l5-5v16l-5-5H0Zm11-7.03A4.5 4.5 0 0 1 13.5 8c0 1.77-1.02 3.29-2.5 4.02V3.97Z"
      fill="#E09164"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgVolumeDown);
export default ForwardRef;
