import * as React from 'react';
import { forwardRef } from 'react';

const SvgDrag = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m16.071.828-1.414 1.415 4.242 4.242-4.242 4.243 1.414 1.414L20.314 7.9l1.414-1.415L16.07.828Z"
      fill="#fff"
    />
    <mask
      id="Drag_svg__a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={14}
      y={0}
      width={8}
      height={13}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m16.071.828-1.414 1.415 4.242 4.242-4.242 4.243 1.414 1.414L20.314 7.9l1.414-1.415L16.07.828Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#Drag_svg__a)">
      <path fill="#E09164" d="M1-7h30v30H1z" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m5.929.828 1.414 1.415-4.242 4.242 4.242 4.243-1.414 1.414L1.686 7.9.272 6.485 5.93.828Z"
      fill="#fff"
    />
    <mask
      id="Drag_svg__b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={8}
      height={13}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m5.929.828 1.414 1.415-4.242 4.242 4.242 4.243-1.414 1.414L1.686 7.9.272 6.485 5.93.828Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#Drag_svg__b)">
      <path fill="#E09164" d="M21-7H-9v30h30z" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgDrag);
export default ForwardRef;
