import get from 'lodash/get';

export function useTranslate({ lang, translations, defaultLang }) {
  return (key) => {
    const text =
      get(translations, [lang, key]) || get(translations, [defaultLang, key]);

    if (text) return text;

    throw new Error(`Missing translation for ["${lang}", "${key}"]`);
  };
}
